<template>
  <v-container fluid class="mb-0 gps-page" style="margin-bottom: 0 !important">
    <v-row :class="this.$store.state.auth.trialBannerDisplay ? 'gps-map large-height' : 'gps-map'">
      <v-col class="mb-0 filters" cols="12" xl="3" lg="4" md="5" sm="6" style="height: 100%">
        <google-maps-tabs
          :selectedTab="selectedTab"
          :transfers="transfers"
          :drivers="drivers"
          :activeTransferId="activeTransferId"
          :activeDriver="activeDriver"
          :userIsCustomer="user.role.id === 11"
          @tabChanged="handleTabChange"
          @handleActiveTransfer="handleActiveTransfer"
          @handleActiveDriver="handleActiveDriver"
          @loadAllData="loadAllData"
          @loadAllDriversData="(affiliate) => loadAllDriversData(affiliate)">
        </google-maps-tabs>
      </v-col>
      <v-col cols="12" class="pa-0 d-none d-sm-block" xl="9" lg="8" md="7" sm="6" style="height: 100%">
        <google-maps
          :selectedTab="selectedTab"
          :transfers="transfers"
          :drivers="drivers"
          :activeTransferId="activeTransferId"
          :activeDriver="activeDriver"
          :window="window"
          @handleActiveTransfer="handleActiveTransfer"
          @handleActiveDriver="handleActiveDriver"></google-maps>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour name="gpsTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import GoogleMapsTabs from '@/components/gps-map/GoogleMapsTabs.vue';
import GoogleMaps from '@/components/gps-map/GoogleMaps.vue';
import { formatDate } from '@/utils/formatDate';
import { formatTime } from '@/utils/formatDate';
import { getAllTourSteps, gpsPageSteps } from '@/mixins/onboarding-tutorial-steps';
import i18n from '@/i18n/i18n';

export default {
  name: 'GPSMap',
  props: [],
  components: { GoogleMapsTabs, GoogleMaps },
  data() {
    return {
      transfers: [],
      drivers: [],
      activeTransferId: null,
      activeDriver: null,
      selectedTab: 'drivers', // we need selected tab info in order to show transfers or drivers pins on map
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: false,
      },
      window: {
        width: 0,
      },
      user: {},
    };
  },
  created() {
    this.user = this.$store.getters['auth/user'];
    this.loadAllData();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    
  },
  methods: {
    async loadAllDrivingsData() {
      let path = 'gpsMapAPI/getDrivingsData';

      await this.$store.dispatch(path).then((res) => {
        this.transfers = res.data.map((item) => {
          return {
            ...item,
            user_id: parseInt(item.user_id),
            time: formatTime(item.pickup_time),
            date: formatDate(item.pickup_time),
            // we format coordinate object beacuse of google maps
            latest_gps_coordinate: {
              lat: item?.latest_gps_coordinate?.latitude,
              lng: item?.latest_gps_coordinate?.longitude,
            },
            profile: {
              ...item?.profile,
              full_name:
                item?.profile?.first_name && item?.profile?.last_name
                  ? item?.profile?.first_name + ' ' + item?.profile?.last_name
                  : '',
            },
            // we add active status to know if the transfer is ongoing or upcoming
            active_status: this.getTransferActiveStatus(item),
          };
        });
      });
    },
    async loadAllDriversData(affiliate) {
      let path = 'gpsMapAPI/getDriversData';

      if (this.user.role.id === 11) {
        path = 'customers/getDriversGpsData';
      }

      await this.$store.dispatch(path, { affiliate: affiliate ? true : false }).then((res) => {
        this.drivers = res.data.map((item) => {
          return {
            ...item,
            // we format coordinate object beacuse of google maps
            latest_gps_coordinate: {
              lat: item?.latest_gps_coordinate?.latitude,
              lng: item?.latest_gps_coordinate?.longitude,
              created_at: item?.latest_gps_coordinate?.created_at,
            },
            profile: { ...item?.profile, full_name: item?.profile?.first_name + ' ' + item?.profile?.last_name },
            driving: {
              ...item?.driving,
              pickup_time: formatTime(item?.driving?.pickup_time),
              expected_drop_off_time: formatTime(item?.driving?.expected_drop_off_time),
            },
            // we handle driver status here, based on backend response, because we use it in many files
            status: this.getDriverStatus(item),
          };
        });
      });
    },
    handleTabChange(tab) {
      // we need selected tab info in order to show transfers or drivers pins on map
      if (tab == 0) {
        this.selectedTab = 'drivers';
      } else if (tab == 1) {
        this.selectedTab = 'transfers';
      } else if (tab == 2) {
        this.selectedTab = 'mobileMaps';
      }
    },
    handleActiveTransfer(id) {
      this.activeTransferId = id; // To know on which pin or transfer we have clicked on
    },
    handleActiveDriver(driver) {
      this.activeDriver = driver; // To know on which pin or driver we have clicked on
    },
    getDriverStatus(driver) {
      if (parseInt(driver?.allow_gps_tracking)) {
        if (driver?.driving?.driving_status) {
          // statuses from backend {1: 'Started', 2: 'Finished', 3: 'Ongoing', 4: 'Pending', 5: 'Canceled'};
          if ([1, 3].includes(driver?.driving?.driving_status)) {
            return 'inTransfer'; // If the driver is driving currently
          } else {
            return 'available'; // If the driver is not driving currently
          }
        } else {
          return 'available'; // If the driver has no driving order assigned
        }
      } else {
        return 'offline'; // If the driver he has turned GPS off
      }
    },
    // we add active_status to know if the transfer is ongoing or upcoming
    getTransferActiveStatus(transfer) {
      // statuses from backend {1: 'Started', 2: 'Finished', 3: 'Ongoing', 4: 'Pending', 5: 'Canceled'};
      if ([1, 3].includes(transfer?.driving_status)) {
        return 'ongoing';
      } else {
        return 'upcoming';
      }
    },
    async loadAllData() {
      await this.loadAllDriversData();

      if (this.user.role.id !== 11) {
        await this.loadAllDrivingsData();
      }

      this.setStepsAndStartTour();
    },
    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },
    startTour() {
      this.$tours['gpsTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },
    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '4')
            ?.map((item) => item.step_id);
          this.steps = gpsPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },
    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '4',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '4',
        step_id: `#gps-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '4',
            step_id: `#gps-page-step-${step}`,
          },
        ],
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },

  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },

  watch: {
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style lang="scss">
.gps-page {
  .gps-map {
    height: calc(100dvh - 120px);

    &.large-height {
      height: calc(100dvh - 176px);
    }
  }
  .filters {
    overflow: scroll;
  }

  @media screen and (max-width: 959px) {
    .gps-map {
      height: calc(100dvh - 112px);

      &.large-height {
        height: calc(100dvh - 168px);
      }
    }
  }

  .v-step {
    @media screen and (min-width: 425px) {
      inset: 0 0 auto 20px !important;
    }
  }
}
</style>
